import moment from 'moment'

export function timeFix () {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome () {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent () {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

export function handleScrollHeader (callback) {
  let timer = 0

  let beforeScrollTop = window.pageYOffset
  callback = callback || function () { }
  window.addEventListener(
    'scroll',
    event => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}

export function uuid() {
  return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

/**
 * @function 将字符串中转义字符转换普通字符
 * @param str 要转换的字符串
 * @return {String}
 */
export function escape2Html (str) {
  const arrEntities = { lt: '<', gt: '>', nbsp: ' ', amp: '&', quot: '"' }
  return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function (word, index) {
    // 该函数共有四个参数
    // 第一个参数是匹配到的字符串
    // 第二个参数是与模式中的子表达式匹配的字符串, 可以有 0 个或多个这样的参数(即，如果有分组，就是匹配到的分组)
    // 第三个参数是一个整数，声明了匹配在 stringObject 中出现的位置
    // 最后一个参数是 stringObject 本身。
    return arrEntities[index]
  })
}

/**
 * 清理空值，对象
 * @param children
 * @returns {*[]}
 */
export function filterEmpty (children = []) {
  return children.filter(c => c.tag || (c.text && c.text.trim() !== ''))
}

/**
 * 获取字符串长度，英文字符 长度1，中文字符长度2
 * @param {*} str
 */
export const getStrFullLength = (str = '') =>
  str.split('').reduce((pre, cur) => {
    const charCode = cur.charCodeAt(0)
    if (charCode >= 0 && charCode <= 128) {
      return pre + 1
    }
    return pre + 2
  }, 0)

/**
 * 截取字符串，根据 maxLength 截取后返回
 * @param {*} str
 * @param {*} maxLength
 */
export const cutStrByFullLength = (str = '', maxLength) => {
  let showLength = 0
  return str.split('').reduce((pre, cur) => {
    const charCode = cur.charCodeAt(0)
    if (charCode >= 0 && charCode <= 128) {
      showLength += 1
    } else {
      showLength += 2
    }
    if (showLength <= maxLength) {
      return pre + cur
    }
    return pre
  }, '')
}

// arr 要遍历的对象数组， name 数组中要访问的每个对象的属性名， result 不重复的x轴坐标
export const getDiffValue = (arr, name) => {
  const result = []
  for (var i = 0; i < arr.length; i++) {
    if (result.indexOf(arr[i][name]) === -1) {
      result.push(arr[i][name])
    }
  }
  return result
}

/**
 * @return {number}
 */
export function FloatAdd (arg1, arg2) {
  let r1, r2
  try {
    r1 = arg1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  const m = Math.pow(10, Math.max(r1, r2))

  return (arg1 * m + arg2 * m) / m
}

/**
 * download url
 * @param url
 * @param filename
 */
export function downloader (url, filename) {
  const a = document.createElement('a')
  a.download = filename
  a.href = url
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export function cleanupChildren (items) {
  if (items && items.length > 0) {
    items.forEach(item => {
      if (item.children && item.children.length > 0) {
        cleanupChildren(item.children)
      } else {
        delete item.children
      }
    })
  }
}

export function clearValidaToResult (validate) {
  if (validate) {
    Object.keys(validate).forEach(k => {
      validate[k] = {}
    })
  }
}

export function saveBlobStream (res) {
  const blob = new Blob([res.data], {
    type: res.headers['content-type']
  }) // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
  const downloadElement = document.createElement('a')
  const href = window.URL.createObjectURL(blob) // 创建下载的链接
  downloadElement.href = href
  downloadElement.download = res.headers['x-suggested-filename'] // 下载后文件名
  document.body.appendChild(downloadElement)
  downloadElement.click() // 点击下载
  document.body.removeChild(downloadElement) // 下载完成移除元素
  window.URL.revokeObjectURL(href)
}

export function getTimesInterval (beforetime) {
  const time = moment(moment(beforetime)).fromNow()
  return time
}

export const repeatArray = (arr, num) => {
  /**
   * 注意新数组和老数组间是浅层复制关系
   * 在一个基础数组上创建一个新数组，
   * 如果新数组长度超过原数组将循环读取原数组，知道长度达到新数组长度，
   * 如果新数组长度小于原数组，将截取
   */
  if (!arr || arr.constructor.name !== 'Array') {
    return []
  }
  const len = arr.length - 1
  let count = 0
  const result = new Array(num).join(',').split(',')
  result.forEach((item, index) => {
    if (count > len) {
      count = 0
    }
    result[index] = arr[count]
    count++
  })
  return result
}
