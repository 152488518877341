import { getDiffValue } from '@/utils/utils'
import { formatNumberThousands } from '@/utils/numberUtil'

export default class ReportChartData {
  id = ''
  title = ''
  originData = {}
  dataItems = []
  foldKeyNames = []
  foldValueNames = []
  foldDataItems = []

  categories = []
  series = []

  geoField = ''

  dimensions = []
  metrics = []
  units = []
  // 指标单位数量
  unitCount = 0

  columns = []

  predictStart = ''
  predictEnd = ''

  // focus x axis values
  focusXAxis = []

  constructor(inputData) {
    this.id = 'chartId' + (new Date().getTime())
    this.originData = JSON.parse(JSON.stringify(inputData))
    this.assignDimensionsAndMetricsAndColumns(this.originData)
    this.assignData(this.originData['data'])
    this.assignCategories()
    this.assignSeries()
    this.title = this.metrics.join('、')
  }

  getTableData () {
    return {
      columns: this.columns,
      data: this.dataItems
    }
  }

  getChartData () {
    return {
      categories: this.categories,
      series: this.series
    }
  }

  // 赋值纬度和度量
  assignDimensionsAndMetricsAndColumns (data) {
    const _category = data['category'] || data['subCategory']
    if (_category) {
      if (_category['geoField']) this.geoField = _category['alias']
      if (this.dimensions.indexOf(_category['name']) === -1) {
        this.dimensions.push(_category['name'])
        this.columns.push({
          dataFieldId: _category['id'],
          title: _category['name'],
          dataIndex: _category['alias'],
          originDataIndex: _category['alias'],
          key: _category['alias'],
          ellipsis: true,
          axisType: 'dimension',
          unit: ''
        })
      }
      this.assignDimensionsAndMetricsAndColumns(_category)
    } else if (data['metrics']) {
      if (data['geoField']) this.geoField = data['alias']
      data['metrics'].forEach(_item => {
        const _title = _item['name'].replace('_' + _item['method'], '')
        const _unit = _item['unit'] || ''
        this.metrics.push(_title)
        this.columns.push({
          dataFieldId: _item['id'],
          title: _title,
          dataIndex: _title,
          originDataIndex: _item['alias'],
          key: _item['alias'],
          ellipsis: true,
          axisType: 'metrics',
          unit: _unit
        })
        let _unitExist = this.units.find(_item1 => _item1['unit'] === _unit)
        if (!_unitExist) {
          _unitExist = {
            unit: _unit,
            metrics: []
          }
          this.units.push(_unitExist)
        }
        _unitExist['metrics'].push(_title)
      })
    }
  }

  assignData (reportData) {
    if (!reportData) return
    reportData.forEach(_dataItem => {
      if (_dataItem) {
        const _tmpDataItem = {}
        this.columns.forEach(column => {
          if (_dataItem['predict'] && column['axisType'] === 'dimension') {
            if (!this.predictStart) this.predictStart = this.formatDataItemVal(column, _dataItem[column['originDataIndex']])
            this.predictEnd = this.formatDataItemVal(column, _dataItem[column['originDataIndex']])
          }
          _tmpDataItem[column['title']] = this.formatDataItemVal(column, _dataItem[column['originDataIndex']])
        })
        this.dataItems.push(_tmpDataItem)
      }
    })
  }

  assignCategories () {
    if (this.dimensions.length > 0) {
      // todo  目前只处理了只有一种维度（category）的情况
      const _categoryName = this.dimensions[0]
      this.dataItems.forEach(dataItem => {
        if (!this.categories.find(category => category === dataItem[_categoryName])) {
          this.categories.push(dataItem[_categoryName])
        }
      })
    }
  }

  assignSeries () {
    if (this.dimensions.length === 1) {
      this.assignSeriesOneCategory()
    } else if (this.dimensions.length === 2) {
      this.assignSeriesOnlyTwoCategory()
    }
  }

  assignSeriesOneCategory () {
    this.series.push(...this.metrics.map(metricsItem => {
      return {
        name: metricsItem,
        data: []
      }
    }))
    const _categoryName = this.dimensions[0]
    this.categories.forEach(categoryItem => {
      this.series.forEach(seriesItem => {
        const _dataItem = this.dataItems.find(dataItem => dataItem[_categoryName] === categoryItem)
        if (_dataItem) {
          seriesItem.data.push(_dataItem[seriesItem['name']] || '')
        }
      })
    })
  }

  assignSeriesOnlyTwoCategory () {
    const _firstCategory = this.dimensions[0]
    const _secondCategory = this.dimensions[1]
    this.dataItems.forEach(dataItem => {
      if (!this.series.find(seriesItem => seriesItem['name'] === dataItem[_secondCategory])) {
        this.series.push({
          name: dataItem[_secondCategory],
          data: []
        })
      }
    })

    const _seriesName = this.metrics[0]
    this.categories.forEach(categoryItem => {
      this.series.forEach(seriesItem => {
        const _dataItem = this.dataItems.find(dataItem => dataItem[_firstCategory] === categoryItem && dataItem[_secondCategory] === seriesItem['name'])
        if (_dataItem) {
          seriesItem.data.push(_dataItem[_seriesName] || '')
        }
      })
    })
  }

  formatDataItemVal (column, val) {
    if (column['axisType'] === 'dimension' && val) {
      val = val.toString().trim()
      if (['月份', '年份', '季度'].indexOf(column['title']) > -1 && /^\d{1,4}$/.test(val)) {
        return val + column['title']
      } else if (column['title'] === '年季度' && /^\d{5,6}$/.test(val)) {
        const _val = val.replace('-', '')
        return _val.replace(/^(\d{4})(\d{1,2})$/g, '$1年$2季度')
      } else if (column['title'] === '年月' && /^\d{5,6}$/.test(val)) {
        const _val = val.replace('-', '')
        return _val.replace(/^(\d{4})(\d{1,2})$/g, '$1年$2月')
      }
    } else if (column['axisType'] === 'dimension' && !val) {
      return '未知'
    } else if (column['axisType'] === 'metrics' && val) {
      return parseFloat(val)
    } else if (column['axisType'] === 'metrics' && !val) {
      return 0
    }
    return val
  }

  /**
   * 去掉dataItems中的一个key
   */
  removeDataItemKey (key) {
    this.dataItems.forEach(item => {
      delete item[key]
    })
    const _dimensionIndex = this.dimensions.findIndex(item => item === key)
    if (_dimensionIndex > -1) this.dimensions.splice(_dimensionIndex, 1)
    const _columnIndex = this.columns.findIndex(item => item['title'] === key)
    if (_columnIndex > -1) this.columns.splice(_columnIndex, 1)
  }

  /**
   * 获取 smart chart types
   * 图表输出形式规则
   */
  getSmartChartTypes (adviseDatetimeXAxis) {
    const _chartTypes = []
    const _metricsLen = this.metrics.length
    const _dimensionsLen = this.dimensions.length
    const _unitsLen = this.units.length
    // 1、指标数量 === 1
    if (_metricsLen === 1) {
      if (_dimensionsLen === 0) {
        // 1.1、维度数量为0
        _chartTypes.push('KeyValue')
      } else if (_dimensionsLen === 1) {
        // 1.2、维度数量为1，时间维度或者业务维度
        if (this.geoField) _chartTypes.push(...['Map'])
        if (this.dataItems.length === 1) {
          _chartTypes.push(...['Column', 'Bar'])
        } else {
          _chartTypes.push(...['Line', 'Column', 'Bar', 'Area'])
          // 维度成员小于12个
          if (this.dataItems.length <= 12) _chartTypes.push(...['Ring'])
          // if (this.dataItems.length <= 12) _chartTypes.push(...['Ring', 'Pie'])
        }
        _chartTypes.push(...['Tree', 'Bubble', 'Table'])
      } else if (_dimensionsLen === 2) {
        // 1.3、维度数量为2
        if (adviseDatetimeXAxis) {
          // 1.3.1、时间+业务维度组合
          if (this.series.length === 1) {
            _chartTypes.push(...['Column', 'FacetColumn', 'Tree', 'Bubble', 'Table'])
          } else {
            _chartTypes.push(...['Column', 'Line', 'Area', 'FacetColumn', 'FacetLine', 'FacetArea', 'Tree', 'Bubble', 'Table'])
            // _chartTypes.push(...['Column', 'Line', 'Bar', 'Area', 'FacetColumn', 'FacetLine', 'FacetBar', 'FacetArea', 'Tree', 'Table'])
          }
        } else {
          // 1.3.2、两个业务维度组合
          if (this.getGroupConsistent()) { // 1.3.2.1、交叉分组一致
            if (this.series.length === 1) {
              _chartTypes.push(...['Column', 'FacetColumn', 'Table'])
            } else {
              _chartTypes.push(...['Column', 'Line', 'FacetColumn', 'FacetLine', 'Table'])
            }
          } else { // 1.3.2.2、交叉分组不一致
            _chartTypes.push(...['BarTable', 'Tree', 'Bubble', 'Table'])
            // _chartTypes.push(...['Ring', 'FacetColumn', 'FacetLine', 'Tree', 'Bubble', 'Table'])
            // _chartTypes.push(...['Ring', 'FacetColumn', 'FacetLine', 'FacetBar', 'Tree', 'Table'])
          }
        }
      } else if (_dimensionsLen >= 3) {
        // 1.4、维度数量大于等于3
        _chartTypes.push('Table')
      }
    } else if (_metricsLen >= 2) {
      // 2、指标数量 >= 2
      // 2.1、维度数量为0
      if (_dimensionsLen === 0) {
        _chartTypes.push(...['KeyValue', 'Ring'])
      } else if (_dimensionsLen === 1) {
        // 2.2、维度数量为1（时间维度或者业务维度）
        if (_unitsLen === 1) {
          // 2.2.1 指标单位相同（1个）
          if (this.dataItems.length === 1) {
            _chartTypes.push(...['ColumnGroup', 'Table'])
          } else {
            _chartTypes.push(...['Line', 'Area', 'ColumnGroup', 'Point', 'Table'])
          }
        } else if (_unitsLen === 2) {
          // 2.2.2 指标单位不同（2个）
          if (this.dataItems.length === 1) {
            _chartTypes.push(...['LineColumn', 'Table'])
          } else {
            _chartTypes.push(...['LineColumn', 'Point', 'Table'])
          }
        } else {
          // 2.2.3 指标单位不同（3个及以上）
          _chartTypes.push(...['Point', 'Table'])
        }
      } else if (_dimensionsLen === 2) {
        // 2.3、维度数量为2
        // 2.3.1、一个时间维度+一个普通维度
        if (adviseDatetimeXAxis) {
          if (_unitsLen === 1) {
            // 2.3.1.1 指标单位相同（1个）
            if (this.series.length === 1) {
              _chartTypes.push(...['ColumnGroup', 'Table'])
            } else {
              _chartTypes.push(...['ColumnGroup', 'Line', 'Area', 'Point', 'Table'])
            }
            // _chartTypes.push(...['ColumnGroup', 'Bar', 'Line', 'Area', 'Table'])
          } else if (_unitsLen === 2) {
            // 2.3.1.2 指标单位不同（2个）
            _chartTypes.push(...['FacetLineColumn', 'Point', 'Table'])
          } else {
            // 2.3.1.3 指标单位不同（3个及以上）
            _chartTypes.push(...['Point', 'Table'])
          }
        } else {
          // 2.3.2、二个普通维度
          if (_unitsLen === 1) {
            // 2.3.2.1 指标单位相同（1个）
            _chartTypes.push(...['BarTable', 'Bubble', 'Table'])
            // _chartTypes.push(...['ColumnGroup', 'Bar', 'Line', 'Area', 'Table'])
          } else if (_unitsLen === 2) {
            // 2.3.2.2 指标单位不同（2个）
            _chartTypes.push(...['Point', 'FacetLineColumn', 'Table'])
          } else {
            // 2.3.2.3 指标单位不同（3个及以上）
            _chartTypes.push('Point', 'Table')
          }
        }
      } else {
        // 2.4、维度数量大于等于
        _chartTypes.push('Point', 'Table')
      }
    } else {
      // 无指标
      _chartTypes.push('Table')
    }
    return _chartTypes
  }

  /**
   * 判断维度组合分组是否一致
   */
  getGroupConsistent () {
    const DataSet = require('@antv/data-set')
    const dimensions1Len = getDiffValue(this.dataItems, this.dimensions[0]).length
    const dimensions2Len = getDiffValue(this.dataItems, this.dimensions[1]).length
    let col1 = '' // 表格第一列
    let col2 = '' // 表格第二列
    if (dimensions1Len > dimensions2Len) { // 短的是第一列
      col2 = this.dimensions[0]
      col1 = this.dimensions[1]
    } else {
      col2 = this.dimensions[1]
      col1 = this.dimensions[0]
    }
    const dv = new DataSet.View().source(this.dataItems)
    dv.transform({
      type: 'partition',
      groupBy: [col1], // 分组
      orderBy: [col2] // 排序
    })
    const groupRowsData = dv.rows
    const yArray = getDiffValue(this.dataItems, col1)
    const xArray = getDiffValue(this.dataItems, col2).sort()
    let consistent = true
    yArray.forEach((item) => {
      const dataArray = getDiffValue(groupRowsData['_' + item], col2)
      if (!this.arrayEqual(xArray, dataArray)) consistent = false
    })
    return consistent
  }

  /**
   * 判断两个数组内容
   */
  arrayEqual (array, other) {
    if (!array) return false
    if (!other) return false
    if (array.length !== other.length) return false
    for (let i = 0, len = array.length; i < len; i++) {
      if (array[i] instanceof Array && other[i] instanceof Array) {
        if (!this.arrayEqual(array[i], other[i])) {
          return false
        }
      } else if (array[i] !== other[i]) {
        return false
      }
    }
    return true
  }

  keyValueToString () {
    if (this.dataItems.length !== 1) return []
    const _kvs = this.dataItems[0]
    const _keys = Object.keys(_kvs)
    const _result = []
    _keys.forEach((_key) => {
      const _val = _kvs[_key]
      _result.push({
        name: _key,
        value: formatNumberThousands(_val),
        unit: this.getUnitNameByMetrics(_key)
      })
    })
    return _result
  }

  getUnitNameByMetrics (metrics) {
    const _exist = this.units.find(item => item['metrics'].indexOf(metrics) > -1)
    return _exist ? _exist['unit'] : ''
  }
}
